import request from '@/utils/request'

export function findOneById(id) {
  return request({
    url: 'api/evaluation/stuScore/byId/' + id,
    method: 'get',
  })
}

export default { findOneById }
