<template>
  <div id="StuPersonalAwardDetail">
    <van-cell-group title="学生信息">
      <van-field v-model="row.stuName" readonly label="姓名" />
      <van-field v-model="row.stuNo" readonly label="学号" />
      <van-field v-model="row.collName" readonly label="院系" />
      <van-field v-model="row.specName" readonly label="专业" />
      <van-field v-model="row.className" readonly label="班级" />
      <van-field v-model="row.gradeNo" readonly label="年级" />
      <van-field v-model="row.conslrStaffName" readonly label="辅导员" />
    </van-cell-group>
    <van-cell-group title="课程信息">
      <van-field v-model="row.xn" readonly label="学年" />
      <van-field v-model="row.termName" readonly label="学期" />
      <van-field v-model="row.couName" readonly label="课程" />
      <van-field v-model="row.scoreExamMark" readonly label="课程成绩" />
      <van-field v-model="row.creditScore" readonly label="学分" />
      <van-field v-model="row.examNatureName" readonly label="考试类型" />
      <van-field v-model="row.weekClassHour" readonly label="周课时" />
      <van-field v-model="row.totalClassHour" readonly label="总课时" />
    </van-cell-group>
  </div>
</template>

<script>
import { statustag, parseTime } from "@/utils";
import { findOneById } from '@/api/modules/daily/achievement'

export default {
  name: "ScoreDetail",
  dicts: ["xj_shzt"],
  data() {
    return {
      row: {
      },
    };
  },
  created() {
    this.$nextTick((e) => {
      if (this.$route.query && this.$route.query.id) {
        findOneById(this.$route.query.id).then((res) => {
          this.row = res
        })
      }
    });
  },
  methods: {
    statustag,
    parseTime,
    onClickLeft() {
      this.row = {};
      this.$router.go(-1);
    },
  },
};
</script>

<style  lang='scss' scoped>
@import '~@/assets/styles/student/award.scss';
.textarea-style {
  height: 100% !important;
}
.radius-st {
  position: absolute;
  right: 5%;
  top: 20%;
}
.van-cell__title {
  text-align: left;
  min-width: 70%;
}
.van-cell__title span {
  display: inline-block;
  text-align: left;
  word-break: break-all;
}
</style>
